import React, { Component } from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const reports = [
  { year: 2012, title: '제3기 결산공고', uri: '/reports/2012_report.pdf' },
  { year: 2013, title: '제4기 결산공고', uri: '/reports/2013_report.pdf' },
  { year: 2014, title: '제5기 결산공고', uri: '/reports/2014_report.pdf' },
  { year: 2015, title: '제6기 결산공고', uri: '/reports/2015_report.pdf' },
  { year: 2016, title: '제7기 결산공고', uri: '/reports/2016_report.pdf' },
  { year: 2017, title: '제8기 결산공고', uri: '/reports/2017_report.pdf' },
  { year: 2018, title: '제9기 결산공고', uri: '/reports/2018_report.pdf' },
  { year: 2019, title: '제10기 결산공고', uri: '/reports/2019_report.pdf' },
  { year: 2020, title: '제11기 결산공고', uri: '/reports/2020_report.pdf' },
  { year: 2021, title: '제12기 결산공고', uri: '/reports/2021_report.pdf' },
  { year: 2022, title: '제13기 결산공고', uri: '/reports/2022_report.pdf' },
  { year: 2023, title: '제14기 결산공고', uri: '/reports/2023_report.pdf' },
];

class Report extends Component {
  render() {
    return (
      <ScreenClassRender
        render={screenClass => {
          const isMobile = ['xs', 'sm'].includes(screenClass);

          return (
            <div className={cx('contentWrapper')}>
              <Container>
                <Row>
                  {reports.map((report, index) => {
                    const { year, title, uri } = report;
                    const hasBottomLine = reports.length - (isMobile ? 1 : 2) <= index;

                    return (
                      <Col key={index} xs={12} md={4.8} offset={{ md: index % 2 === 0 ? 1 : 0.2 }}>
                        <div className={cx(['item', { bottomLine: hasBottomLine }])}>
                          <div className={cx('left')}>
                            <div className={cx('date')}>{year}년도</div>
                            <div className={cx('title')}>{title}</div>
                          </div>
                          <a href={uri} target="_blank" rel="noopener noreferrer" className={cx('download')}>
                            <div className={cx('text')}>다운로드</div>
                            <Ionicon icon="md-download" fontSize="17px" className={cx('icon')} />
                          </a>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </div>
          );
        }}
      />
    );
  }
}

export default Report;
