import React from 'react';

import Report from 'components/Report';
import PageTitleBar from 'components/misc/PageTitleBar';

const ReportPage = () => (
  <div>
    <PageTitleBar title="경영공시" titleLangKor backgroundImage={require('../assets/images/banner/agreement.jpg')} />
    <Report />
  </div>
);

export default ReportPage;
